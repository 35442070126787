import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit';
import { API_CALL } from '../middleware/api';
import { ReactElement } from 'react';

interface BadgeVersion {
  id: string,
  title: string,
  image_url_1x: string,
  image_url_2x: string,
  image_url_4x: string,
}

interface BadgeSet {
  set_id: string,
  versions: { [version: string]: BadgeVersion }
}

type BadgeSets = {
  [set_id: string]: BadgeSet,
}

export type Video = {
  streamer: string;
  youtubeId: string;
  streamStartedAt: string,
  badgeSets: BadgeSets,
  chat: JsonChatMsg[],
  loaded: boolean,
  error?: string,
};

const videoSlice = createSlice<Video, SliceCaseReducers<Video>, string>({
  name: 'video',
  initialState: {
    streamer: '',
    youtubeId: '',
    streamStartedAt: '',
    badgeSets: {},
    chat: [],
    loaded: false,
    error: undefined
  },
  reducers: {
    VideoLoading() { },
    VideoLoaded(state, response) {
      state.loaded = true;
      state.streamer = response.payload.data.streamer;
      state.youtubeId = response.payload.data.youtubeId;
      state.streamStartedAt = response.payload.data.streamStartedAt;
      if (response.payload.data.channelBadges !== null) {
        response.payload.data.channelBadges.forEach((set: any) => {
          if (state.badgeSets.hasOwnProperty(set.set_id)) {
            // don't allow global sets to override channel sets
            return;
          }
          state.badgeSets['' + set.set_id] = {
            set_id: set.set_id,
            versions: {}
          }
          set.versions.forEach((version: BadgeVersion) => {
            state.badgeSets['' + set.set_id].versions[version.id] = version;
          });
        })
      }
    },
    VideoError(state, error) {
      state.error = error.payload.message;
    },
    ChatLoaded(state, response) {
      const messages = response.payload.data // .map((msg: JsonChatMsg) => ChatMsg.fromJson(msg))
      state.chat = messages;
    }
  },
});

export const fetchVideo = (id: string) => {
  return {
    [API_CALL]: {
      request: {
        endpoint: "/api/vod/" + id,
      },
      types: [VideoLoading, VideoLoaded, VideoError],
      authenticated: false,
    }
  }
}

export const fetchChat = (id: string) => {
  return {
    [API_CALL]: {
      request: {
        endpoint: "/api/vod/" + id + "/chat.json",
      },
      types: [VideoLoading, ChatLoaded, VideoError],
      authenticated: false,
    }
  }
}

export const { VideoLoading, VideoLoaded, VideoError, ChatLoaded } = videoSlice.actions;

export default videoSlice.reducer;

export class ChatMsg {
  readonly username: string;
  readonly message: string;
  readonly color: string;
  readonly ts: Date;
  readonly emotes: Emote[];
  readonly badges: Badges;
  constructor(username: string, message: string, color: string, ts: Date, emotes: Emote[], badges: Badges) {
    this.username = username;
    this.message = message;
    this.color = color;
    this.ts = ts;
    this.emotes = emotes;
    this.badges = badges;
  }

  static fromJson(msg: JsonChatMsg) {
    return new ChatMsg(
      msg.username,
      msg.message,
      msg.color,
      new Date(msg.datetime),
      ChatMsg.parseEmotes(msg.emotes),
      ChatMsg.parseBadges(msg.badges),
    );
  }

  static parseEmotes(emotes: string): Emote[] {
    if (emotes === "") {
      return [];
    }
    if (emotes === undefined) {
      console.log("huh, undefined emotes")
      return [];
    }

    return emotes.split("/").map(s => ChatMsg.parseEmote(s))
  }

  static parseEmote(emote: String): Emote {
    let [id, positions] = emote.split(":")

    return new Emote(
      id,
      positions.split(",").map(function(pos: string) {
        let [start, end] = pos.split("-").map(x => parseInt(x, 10))
        return { pos, start, end }
      })
    )
  }

  static parseBadges(s: string): Badges {
    let badges = new Badges()
    if (s === undefined || s === "") {
      return badges;
    }

    s.split(",").forEach(b => {
      let [set_id, version] = b.split("/");
      badges.push({ set_id, version });
    });

    return badges;
  }

  postedBefore(other: Date): boolean {
    return this.ts < other;
  }

  isAction(): boolean {
    return this.message.startsWith("\u0001ACTION");
  }

  render(key: number, badgeSets: BadgeSets): ReactElement {
    let text = this.message;
    if (this.isAction()) {
      text = text.substring(8, this.message.length - 1);
    }

    // deal with unicode characters, not with code points
    let chars = [...text];

    let offset = 0;
    let textParts: (ReactElement|string)[] = [];
    this.emotes.forEach(function(emote: Emote, index: number) {
      emote.positions.forEach(function(pos, index2: number) {
        if (offset < pos.start) {
          textParts.push(chars.slice(offset, pos.start).join(""))
        }

        const emoteName = chars.slice(pos.start, pos.end + 1).join("")
        textParts.push(
          <span className="icon is-16x16" key={`emote-${index}-${index2}`}>
            <img src={`https://static-cdn.jtvnw.net/emoticons/v2/${emote.id}/default/dark/1.0`} alt={emoteName} title={emoteName} />
          </span>
        );

        offset = pos.end + 1
      });
    });

    if (offset < text.length) {
      textParts.push(chars.slice(offset).join(""))
    }

    return <div className="chat-msg" data-raw={text} key={`msg-${key}`}>
      <span>
        {this.badges.render(badgeSets)}
        <span style={{ color: this.color, marginRight: '3px' }}>{this.username}</span>
      </span>
      {textParts}
    </div>;
  }
}

class Emote {
  readonly id: string;
  readonly positions: { pos: string, start: number, end: number }[]
  constructor(id: string, positions: { pos: string, start: number, end: number }[]) {
    this.id = id;
    this.positions = positions;
  }

  render(): ReactElement {
    return <>EMOTE</>;
  }
}

interface Badge {
  set_id: string;
  version: string;
}

class Badges extends Array<Badge> {
  render(badgeSets: BadgeSets): ReactElement {
    let badges: ReactElement[] = [];
    this.forEach(badge => {
      if (!badgeSets.hasOwnProperty(badge.set_id)) {
        console.warn('Unknown badge found: ' + badge.set_id)
        return
      }
      const set = badgeSets[badge.set_id];
      if (!set.versions.hasOwnProperty(badge.version)) {
        console.warn('Unknown version found for ' + badge.set_id + ': ' + badge.version)
        return
      }
      const version = set.versions[badge.version];

      badges.push(
        <span className="icon is-16x16" key={"badge-" + set.set_id + "/" + version.id}>
          <img src={version.image_url_1x} className="badge" title={version.title} alt={version.title} />
        </span>
      );
    });

    return <>{badges}</>;
  }
}

export interface JsonChatMsg {
  datetime: string;
  username: string;
  message: string;
  color: string;
  emotes: string;
  badges: string;
}
